<template>
    <hb-modal v-model="dialog" show-help-link size="large" :title="selected.id ? 'Edit Category' : 'Add Category'" @close="$emit('close')">
        <template v-slot:content>

            <hb-form
                label="Category Name"
                required
            >
                <hb-text-field
                    v-model="category.name"
                    v-validate="'required|max:45'"
                    placeholder="Enter Category Name"
                    name="Name"
                    data-vv-as="Category Name"
                    :error="errors.has('Name')"
                >
                </hb-text-field>
            </hb-form>

            <hb-form
                label="Category Description"
            >
                <hb-textarea
                    v-model="category.description"
                    v-validate="'max:1000'"
                    placeholder="Enter Category Description"
                    name="Description"
                    data-vv-as="Category Description"
                    :error="errors.has('Description')"
                >
                </hb-textarea>
            </hb-form>

            <hb-form
                v-for=" (a, i) in category.Attributes"
                :key="a.id"
                :label="a.name"
                required
                full
            >
                <v-row no-gutters>

                    <hb-text-field
                        v-model="a.value"
                        v-validate="'required|max:45'"
                        :placeholder="'Enter ' + a.name"
                        :name="a.name"
                        :error="errors.has(a.name)"
                        width="370px"
                    >
                    </hb-text-field>

                    <hb-tooltip>
                        <template v-slot:item>
                            <hb-icon @click="removeAttribute(i)" class="hand ml-2">mdi-close</hb-icon>
                        </template>
                        <template v-slot:body>
                            Delete Attribute
                        </template>
                    </hb-tooltip>

                </v-row>
            </hb-form>

            <hb-form
                v-show="addFeature"
                label="New Attribute"
                required
                full
            >
                <v-row no-gutters>

                    <v-col cols="4" class="pr-3">
                        <hb-select
                            v-model="newAttribute.Amenity"
                            v-validate="addFeature ? 'required' : ''"
                            :items="features"
                            return-object
                            item-text="name"
                            placeholder="Select Type"
                            name="new_attribute"
                            data-vv-scope="add"
                            data-vv-as="New Attribute Type"
                            :error="errors.has('add.new_attribute')"
                        >
                        </hb-select>
                    </v-col>

                    <v-col cols="8">
                        <hb-text-field
                            v-model="newAttribute.value"
                            v-validate="addFeature ? 'required|max:45' : ''"
                            placeholder="Enter Value"
                            name="new_param"
                            data-vv-scope="add"
                            data-vv-as="New Attribute Value"
                            :error="errors.has('add.new_param')"
                        >
                        </hb-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters class="my-n2">
                    <hb-btn color="primary" @click="setNewFeature" small >Save</hb-btn>
                    <hb-link @click="resetNewFeature" class="ml-2" style="margin-top:6px;">Cancel</hb-link>
                </v-row>

            </hb-form>

            <hb-form v-show="!addFeature" label="">
                <hb-link @click="newFeature">+ Add Attribute</hb-link>
            </hb-form>

            <hb-form
                label="Space Type"
                required
            >
                <hb-select
                    v-model="category.unit_type"
                    v-validate="'required'"
                    :items="unitTypes"
                    placeholder="Select Space Type"
                    name="unit_type"
                    data-vv-as="Space Type"
                    :error="errors.has('unit_type')"
                >
                    <template v-slot:selection="{ item }">
                        <div style="padding-bottom: 6px;">{{item | capitalize }}</div>
                    </template>
                    <template v-slot:item="{ item }">
                        {{item | capitalize }}
                    </template>
                </hb-select>
            </hb-form>

            <hb-form
                label="Price"
                description="This price field accepts any text and is for display purposes only."
                last
            >
                <hb-text-field
                    v-model="category.price"
                    v-validate="'max:45'"
                    placeholder="Enter Price (e.g. $21.00, 10.00 - $50.00, From $35.00)"
                    name="Price"
                    :error="errors.has('Price')"
                >
                </hb-text-field>
            </hb-form>

        </template>
        <template v-slot:actions>
            <hb-btn :disabled="isLoading($options.name) || addFeature" color="primary" @click="save" :loading="isLoading($options.name)">Save</hb-btn>
        </template>
    </hb-modal>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import api from '../../assets/api.js';


    export default {
        name: "EditCategory",
        data() {
            return {
	            addFeature: false,
                category:{
	                name: '',
	                description: '',
	                price: '',
	                Attributes: []
                },
	            features: [],
	            newAttribute: {
		            category_id: '',
		            value: '',
		            amenity_id: '',
		            Amenity:null
                },
                unitTypes: [
                    'residential',
                    'storage',
                    'parking'
                ],
            }
        },
        components:{
            Loader,
            Status,
	        Dropdown
        },
        props:[
            'selected', 'value'
        ],
        async created(){
	        await this.fetchAmenities();
            if(this.selected.id){
                this.category = {
                    name: this.selected.name,
                    description: this.selected.description,
                    unit_type: this.selected.unit_type,
                    price: this.selected.price,
                    Attributes: JSON.parse(JSON.stringify(this.selected.Attributes || []))
                };
            }
        },
        computed: {
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        methods:{
	        setNewFeature(){

                this.$validator.validateAll('add').then(status => {
                    if(!status) return;
                    this.category.Attributes.push({
                        amenity_id: this.newAttribute.Amenity.id,
                        value: this.newAttribute.value,
                        name: this.newAttribute.Amenity.name,
                        category_id: this.selected.id,
                    });
                    this.resetNewFeature();
                });
            },
            resetNewFeature(){

	            this.newAttribute = {
		            category_id: '',
                    value: '',
                    amenity_id: '',
                    Amenity:null
                }

                this.$validator.reset();
                
                this.addFeature = false;
            },
	        newFeature(){
	        	this.newAttribute = {
                    category_id: this.selected.id,
                    value: '',
                    amenity_id: '',
                    Amenity:null
		        }

		        this.addFeature = true;
            },
	        removeAttribute(index){
	        	this.category.Attributes.splice(index, 1);
            },
	        async fetchAmenities(){
		        let response = await api.get(this, api.UNITS + 'features/storage');
		        this.features = response.features;
	        },
            save(){

                this.validate(this).then(status => {
                    if(!status) return;
                    if(this.selected.id){
                        api.put(this, api.CATEGORIES + this.selected.id,  this.category).then(r => {
                            this.$emit('refetch');
                            this.$emit('close');
                        });
                    } else {
                        api.post(this, api.CATEGORIES,  this.category).then(r => {
                            this.$emit('refetch');
                            this.$emit('close');
                        });
                    }
                })
            },
        }
    }
</script>
